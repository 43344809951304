.topics {
  .topic-tile-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

    .topic-tile {
      opacity: 1;
      flex: 1;
      padding: 2rem 1rem;
      margin: 1rem;
      color: white;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
      cursor: pointer;
      border: 1px solid rgb(173, 173, 173);
      > div {
        flex: 0;
      }
      > i {
        margin-right: 0.5rem;
      }
    }

    .topic-tile:hover {
      box-shadow: 0 3px 2px #000;
    }
  }
}
