.features-split {
  .deviceContainer {
    display: block;
  }
  .app-screenshot {
    max-width: 12rem !important;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
